import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import img1 from '../../assets/Yoga/alle/group-of-young-sporty-people-in-uttanasana-exercise.jpg';
import img2 from '../../assets/Yoga/alle/group-of-young-sporty-people-sitting-in-padmasana-pose.jpg';

function YogaAlle() {
  return (
    <>
        <NavigationBar/>
        <section className='schwanger-head'>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={img1} className='schwanger-img' alt="schwanger1"/>
            </div>
          </div>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={img2} className='schwanger-img' alt="schwanger2"/>
            </div>
          </div>
        </section>
        <section className='schwanger-head-label'>
          <h2>Yoga für alle</h2>          
          <p>In diesem Kurs kräftigst du ganz individuell deinen ganzen Körper für die Herausforderungen des Alltags. Ich bringe dir bei, wie du Stress lösen kannst, körperlich und geistig. Für mehr Entspannung und ein insgesamt positiveres Leben!</p>
        </section>

        <section className='yoga-alle-details'>
            <h3>Wann & wo findet der Kurs statt?</h3>
            <br/>
            <p><strong>Dienstags 19.15 bis 20.30 Uhr (außer den 1. Dienstag im Monat)</strong>,<br/> in der alten Sparkasse (Dorfgemeinschaftshaus) <br/> Bahnhofstraße 36 in 27389 <strong>Lauenbrück</strong></p>
            <br/>
            <p>VHS: <strong>Donnerstags 18:15 bis 19.30 Uhr</strong>, in der alten Sparkasse (Dorfgemeinschaftshaus) <br/> Bahnhofstraße 36 in 27389 <strong>Lauenbrück</strong></p>
            <br/>
            <p>Kneippverein: <strong>Donnerstags 19:45 bis 21.00 Uhr</strong>, in der alten Sparkasse (Dorfgemeinschaftshaus) <br/>Bahnhofstraße 36 in 27389 <strong>Lauenbrück</strong></p>
            <br/>
            <hr className='solid'/>
            <br/>
            <h3>Was bietet dir der Kurs?</h3>
            <ul>
              <li>Komme in Bewegung und erfahre die positiven und entspannenden Wirkungen der Streckung und Dehnung durch Yoga</li>
              <li>Übe Stück für Stück aus deiner körperlichen Komfortzone heraus zu kommen, um deinem Körper neue Kraft zu schenken</li>
              <li>Trainiere alltagstaugliche Techniken für mehr Entspannung und ein positives Selbstwertgefühl</li>
              <li>Lerne, wie du körperliche Beschwerden durch gezielte Übungen lindern oder ihnen vorbeugen kannst</li>
            </ul>
            <br/>
            <hr className='solid'/>
            <br/>
            <h3>Was brauchst du für den Kurs?</h3>
            <br/>
            <p>Bitte bring dir folgendes zu jedem Kurstag mit:</p>
            <ul>
              <li>eine leichte (Woll-)<strong>Decke</strong></li>
              <li>ein <strong>Getränk</strong></li>
            </ul>
            <br/>
            <p>Wenn du eine eigene Matte oder Blöcke hast, kannst du diese auch gerne mitbringen.</p>
            <br/>
        </section>

        <section className='yoga-alle-kosten'>
          <h3>Träger und Kosten</h3>
          <div className='yoga-alle-kosten-container'>
          <div className='yoga-alle-kosten-column'>
              <strong>Dienstag ab 19:15 Uhr</strong>
              <p><strong>Kosten:</strong> 7 Euro pro Termin, buchbar als 10er-Karte. Du kannst 10x innerhalb von 12 aufeinander folgenden Terminen den Kurs besuchen. <strong>Schnuppern möglich!</strong></p>
              {/* <p className='schwanger-center-p'><strong>Der nächste Kurs läuft vom 11.01.-14.03.2024 (9 Termine) und ist 
              &nbsp;<a href="https://www.vhs-row.de/programm/kurs/Yoga-fuer-alle/BD71025" target='_blank' rel="noopener noreferrer">hier</a> buchbar!</strong>
              </p> */}
              <p className='schwanger-center-p'><strong>Der neue Kurs beginnt am 13.08.2024 für 6 Termine bis zum 24.09, Ausfall am 03.09.!</strong>
              </p>
              <p className='schwanger-center-p'>Falls der Kurs voll sein sollte, ist eine Anmeldung auf der Warteliste möglich. So könnt ihr ggf. nachrutschen, falls jemand doch wieder abspringt!</p>
              <p className='schwanger-center-p'><strong>Ihr könnt euch für die Kurse <a href="/kontakt">bei mir</a> anmelden!</strong></p>
            </div>
            <div className='yoga-alle-kosten-column'>
              <strong>Donnerstag Kurs 1 ab 18:15 Uhr</strong>
              <p><strong>Träger</strong>  ist die VHS Rotenburg in Kooperation mit SIMBAV e.V.</p>
              <p><strong>Kosten:</strong> circa 6 Euro pro Kursstunde. <br/>Für Personen mit geringen Einkommen ist eine Ermäßigung bis 75% möglich! <br/>Bitte fragt einfach nach.</p>
              {/* <p className='schwanger-center-p'><strong>Der nächste Kurs läuft vom 11.01.-14.03.2024 (9 Termine) und ist 
              &nbsp;<a href="https://www.vhs-row.de/programm/kurs/Yoga-fuer-alle/BD71025" target='_blank' rel="noopener noreferrer">hier</a> buchbar!</strong>
              </p> */}
              <p className='schwanger-center-p'><strong>Nach den Sommerferien geht es mit dem neuen Kurs weiter, vom 08.08.-26.09.2024 (6 Termine, Ausfall am 05.09. & 19.09.). 
              </strong>
              </p>
              <p><strong>Dieser Kurs und der Folgekurs nach den Herbstferien sind buchbar auf der<a href="https://www.vhs-row.de/ueber-uns/dozenten/dozent/1746" target='_blank' rel="noopener noreferrer">Internetseite der VHS Rotenburg!</a></strong></p>
              <p className='schwanger-center-p'>Falls der Kurs voll sein sollte, ist eine Anmeldung auf der Warteliste möglich. So könnt ihr ggf. nachrutschen, falls jemand doch wieder abspringt!</p>
         
            </div>
            <div className='yoga-alle-kosten-column'>
              <strong>Donnerstag Kurs 2 ab 19:45 Uhr</strong>
              <p><strong>Träger</strong> ist der Kneipp-Verein Rotenburg (Wümme) e.V.</p>
              <p><strong>Kosten</strong>: für Nicht-Mitglieder 6 Euro pro Termin <br/>für Mitglieder 4,50 Euro pro Termin <br/>Eine Mitgliedschaft kostet pro Jahr 24 Euro für eine Einzelperson und 30 Euro als Familie.</p>
              {/* <p className='schwanger-center-p'><strong>Der nächste Kurs läuft vom 11.01.-14.03.2024 (9 Termine).</strong></p> */}
              <p className='schwanger-center-p'><strong>Nach den Sommerferien geht es mit dem neuen Kurs weiter, vom 08.08.-26.09.2024 (6 Termine, Ausfall am 05.09. & 19.09.). </strong></p>
              <p className='schwanger-center-p'>Falls der Kurs voll sein sollte, ist eine Anmeldung auf der Warteliste möglich. So könnt ihr ggf. nachrutschen, falls jemand doch wieder abspringt!</p>
              <p className='schwanger-center-p'><strong>Ihr könnt euch für die Kurse <a href="/kontakt">bei mir</a> anmelden!</strong></p>
            </div>
          </div>
            
            
        
            
        </section>
        <Footer color="yoga"/>
    </>
  );
}

export default YogaAlle;
