import React from 'react';
import NavigationBar from '../navigationbar';
import Footer from '../footer';
import './HomePage.css';
import Logo_breit_Screenshot from '../assets/Logo/Logo_breit_Screenshot.png';

function HomePage() {
  return (
    <>
        <NavigationBar/>
        <section className='main-area'>
          <img className="homepage-logo-komplett" src={Logo_breit_Screenshot} alt="Homescreen"/>
        </section>
        
        <section>
          <div className="hompage-whatIsHerznah">
            <h2 className="homepage-h2">Was ist Herznah?</h2>
            <p>Ich habe meinem Unternehmen den Namen "Herznah" gegeben, weil ich damit meinen persönlichen Herzensthemen folge und gleichzeitig auch dein und euer Herz berühren und zu neuen Wegen inspirieren möchte.</p>
            <p>
              <strong>"Herznah leben" bedeutet für mich:</strong>
              <br/>
              - mehr bei mir sein als bei anderen
              <br/>
              - mir selbst vertrauen
              <br/>
              - meiner Intuition statt (vermeintlichen) gesellschaftlichen Konventionen zu folgen
              <br/>
              - wiiirklich zuhören
              <br/>
              - sich in andere hineinversetzen
              <br/>
              - sie zu verstehen statt zu bewerten und zu verurteilen
              <br/>
              - Selbstreflexion
              <br/>
              - einfach mal etwas zu hinterfragen - gibt es vielleicht auch einen anderen Weg?
              <br/>
              -mich stark machen für meinen Weg und gleichzeitig andere Wege respektieren
            </p>
          </div>
        </section>

        <section className="neuigkeiten-section">
          <div className="neuigkeiten-content">
            <h2>!! Neuigkeiten !!</h2>
            <br/>
            <h2>Die nächsten Kurse starten nach den Ferien: </h2>
            <p>Yoga für Alle <strong><a href="/yogaalle">Dienstag </a></strong></p>
            <p>Yoga für Alle <strong><a href="/yogaalle">Donnerstag VHS</a></strong></p>
            <p>Yoga für Alle <strong><a href="/yogaalle">Donnerstag Kneippverein</a></strong></p>
            <p><strong><a href="/yogakinder">Familienyoga und Kinderyoga</a></strong> am 16.08., 20.09. & 18.10. </p>
            <br/>
            <p>Bei <strong>der Geburtsvorbereitung/dem Coaching</strong> hab ich noch Plätze frei!</p>
            <h3>Willst du mitmachen? Dann melde dich <a href="/kontakt">bei mir!</a></h3>
          </div>
        </section>

        <Footer/>
    </>
  );
}

export default HomePage;
