import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import './coaching.css';

import Logo from '../../assets/Coaching/AdobeStock_163872925.jpeg';

function Coaching() {
  return (
    <>
        <NavigationBar/>
        
        <section className="coaching-picture">
        <div className="coaching-img-content">
          <img className="coaching-main-logo" src={Logo} alt="coaching nature"/>
        </div>
        <div className="coaching-content">
          <p>
          Hast du das Gefühl, in einem Bereich deines Lebens läuft es nicht rund?<br/>
          <br/>
          Nimmt dich ein bestimmtes Gefühl besonders oft oder besonders stark mit?<br/>
          <br/>
          Ärgern dich immer wieder ähnliche Situationen oder Menschentypen?<br/>
          <br/>
          Möchtest du dich weiter entwickeln und wachsen, aber brauchst dabei ein bisschen Unterstützung?<br/>
          <br/>
          Mit meiner einfühlsamen Art und schnell umsetzbaren Werkzeugen begleite ich dich gerne ein Stück auf deiner Reise!<br/>
          <br/>
          <strong>Neugierig geworden?</strong><br/>
          <br/>
          <strong>Dann melde dich bei mir für ein kostenloses Kennenlerngespräch</strong><br/>
          und wir schauen uns einfach einmal gemeinsam an, was du noch brauchst, um deine Ziele zu erreichen.
          </p>
        </div>
      </section>

      <Footer color="coaching"/>
    </>
  );
}

export default Coaching;
